import { Box, BoxProps, Image, Flex } from '@chakra-ui/react';
import Link from 'next/link';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

interface IUserMenuMobileProps {
  displayName: string;
  pictureUrl: string;
}

const textProps: BoxProps = {
  fontSize: '20px',
  lineHeight: '60px',
  color: 'white',
  fontWeight: '700',
};

const menuListProps: BoxProps = {
  marginTop: '31px',
  paddingBottom: '11px',
  marginBottom: '11px',
  borderBottom: '1px solid hsla(0,0%,84.7%,.13)',
};

const menuItemProps: BoxProps = {
  padding: '12px 0',
  fontSize: '18px',
  fontWeight: '500',
  lineHeight: '20px',
  opacity: '.9',
  color: 'white',
};

const menuItems = [
  {
    text: '會員身份',
    url: `/membership`,
  },
  {
    text: '登出',
    url: `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/api/social/v1/sso/logout`,
  },
];

function UserMenuMobile({ displayName, pictureUrl }: IUserMenuMobileProps) {
  return (
    <Box>
      <Flex>
        <Box as="span" marginRight="13px" minW="60px">
          <Image
            borderRadius="full"
            src={
              pictureUrl ||
              'https://ssl.pstatic.net/static/common/myarea/myInfo.gif'
            }
            alt={displayName}
            boxSize="60px"
          />
        </Box>
        <Box className="ellipsis_text one_line" {...textProps} as="span">
          {displayName}
        </Box>
      </Flex>
      <Box {...menuListProps}>
        {menuItems.map((each, index) => (
          <Link href={each.url} key={index}>
            <Box className="PageNavigator" {...menuItemProps}>
              {each.text}
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default UserMenuMobile;
