var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"7456115"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init, replayIntegration } from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

init({
  dsn:
    publicRuntimeConfig.SENTRY_DSN ||
    'https://4ab0fdcd5a154a2d9a0649adebd9549a@sentry-uit.line-apps-beta.com/289',
  environment: publicRuntimeConfig.ENV_VAR || 'dev',
  release: process.env.SENTRY_RELEASE,
  integrations: [replayIntegration()],
  tracesSampleRate: 0.1,
});
