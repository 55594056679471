import { IModalProps } from '@/utils/Stores/Modal';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
} from '@chakra-ui/react';

type BreakLineType = 'Space' | 'NewLine';

export interface IAlertModalProps extends IModalProps {
  title?: string;
  text: string;
  breakLineType?: BreakLineType;
  buttonClassName?: string;
  buttonText: string;
  closeCallback?: () => void;
  closeBtnNeeded?: boolean;
}

function AlertModal({
  title,
  text,
  breakLineType = 'Space',
  buttonClassName = '',
  buttonText,
  closeCallback,
  onClose,
  closeBtnNeeded = true,
}: IAlertModalProps) {
  const newLineStyle =
    breakLineType === 'NewLine'
      ? { whiteSpace: 'pre' as const }
      : { wordBreak: 'keep-all' as const };

  const handleClick = () => {
    closeCallback?.();
    onClose();
  };

  return (
    <Modal
      isOpen
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent width="340px">
        {closeBtnNeeded && (
          <ModalCloseButton
            top="10px"
            right="10px"
            boxSize="34px"
            sx={{ svg: { height: '14px', width: '14px' } }}
          />
        )}
        <ModalBody textAlign="center" padding="35px 0px">
          {title && (
            <Text
              as="strong"
              display="block"
              fontSize="16px"
              lineHeight="19px"
              textAlign="center"
              fontWeight="700"
              margin="0 30px 10px"
            >
              {title}
            </Text>
          )}
          <Box as="p" display="table" w="270px" minH="44px" m="auto auto">
            <Text
              as="em"
              display="table-cell"
              fontSize="15px"
              lineHeight="22px"
              textAlign="center"
              verticalAlign="middle"
              color="#232323"
              fontStyle="normal"
              {...newLineStyle}
            >
              {text}
            </Text>
          </Box>
          <Box marginTop="25px">
            <Button
              className={buttonClassName}
              backgroundColor="#00c55e"
              color="#fff"
              fontWeight="500"
              minWidth="80px"
              onClick={handleClick}
            >
              {buttonText}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default AlertModal;
