import { defineStyleConfig } from '@chakra-ui/react';

export const trackListItemTheme = defineStyleConfig({
  variants: {
    search: {
      p: '5px 0',
    },
    default: {
      p: { base: '5px', xs: '10px', xl: '12px 0' },
      backgroundColor: { base: 'white', xl: 'gray.0' },
      border: { base: '1px solid gray.8', xl: '' },
      borderRadius: { base: '5px', xl: '' },
      m: { base: '15px 0', xl: '0px' },
    },
  },
  defaultProps: {
    variant: 'default',
  },
});
