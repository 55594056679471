import axios, { AxiosInstance } from 'axios';
import getConfig from 'next/config';
import { captureException } from '@sentry/react';
import { userProfileStore } from '@/utils/Stores/Login';
import { modalStore } from '@/utils/Stores/Modal';
import AlertModal from '@/components/Modal/AlertModal';

const { publicRuntimeConfig } = getConfig();

let _axiosInstance: AxiosInstance;

function init() {
  _axiosInstance = axios.create({
    baseURL: publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL,
    headers: {
      'CHANNEL-ID': publicRuntimeConfig.CHANNEL_ID,
    },
  });
  _axiosInstance.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
      if (userProfileStore.isUserLoggedIn()) {
        modalStore.openModal(
          AlertModal,
          {
            text: '您目前的登入階段已過期， 請重新登入。',
            buttonText: '確認',
            closeCallback: () => {
              window.location.href = `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/api/social/v1/sso/logout`;
            },
            closeBtnNeeded: false,
          },
          false
        );

        return Promise.reject('handled');
      } else {
        return Promise.reject(error);
      }
    }
    captureException(error);

    return Promise.reject(error);
  });
}

function setToken(accessToken: string) {
  _axiosInstance.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers['access-token'] = accessToken;

    return config;
  });
}

function getAxiosInstance() {
  if (_axiosInstance === undefined) {
    init();
  }

  return _axiosInstance;
}

export { getAxiosInstance, setToken };
