import { PropsWithChildren } from 'react';
import { Flex, Box } from '@chakra-ui/react';

interface IIdCardProps {
  text: string;
  textColor?: string;
  lineHeight?: string;
}
function IdCard({
  text,
  textColor = '#999',
  lineHeight = '19px',
  children,
}: IIdCardProps & PropsWithChildren) {
  return (
    <Flex fontSize="15px" color={textColor} lineHeight={lineHeight}>
      <Box margin="0 10px 0 7px" h="30px">
        {children}
      </Box>
      <Box lineHeight="30px">{text}</Box>
    </Flex>
  );
}

export default IdCard;
