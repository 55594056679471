export abstract class Store<T> {
  private listeners: (() => void)[] = [];

  public subscribe = (listener: () => void) => {
    this.listeners = [...this.listeners, listener];

    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  };

  public emitChange() {
    for (const listener of this.listeners) {
      listener();
    }
  }

  abstract getSnapShot(): T;
}
