import { defineStyleConfig } from '@chakra-ui/react';

export const closeButtonTheme = defineStyleConfig({
  variants: {
    searchHistory: {
      h: '20px',
      w: '20px',
      fontSize: '16px',
      bg: '',
      color: {
        base: 'white',
        lg: 'gray',
      },
    },
    searchHistoryItem: {
      h: '20px',
      w: '20px',
      fontSize: '8px',
      bg: '',
      color: {
        base: 'white',
        lg: 'gray',
      },
    },
    searchHistoryClean: {
      h: '18px',
      w: '18px',
      fontSize: '8px',
      bg: 'gray.font.light',
      color: 'white',
      borderRadius: '50%',
    },
  },
});
