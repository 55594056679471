import { getAxiosInstance, setToken } from '@/libs/axios';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import TosModal from '@/components/Modal/TosModal';
import { modalStore } from '@/utils/Stores/Modal';
import AlertModal from '@/components/Modal/AlertModal';
import ErrorModal from '@/components/Modal/ErrorModal';
import { userProfileStore } from '@/utils/Stores/Login';

const cookie = new Cookies();

const axiosInstance = getAxiosInstance();

enum ErrorCode {
  SESSION_EXPIRED = 401,
  OUT_OF_SERVICE_REGION = 451,
}

const outOfServiceRegion = `很抱歉
  LINE MUSIC服務僅限註冊
  於台灣的LINE帳號用戶參加
  LINE MUSIC感謝您的支持`;

async function validateUserSession() {
  const { data } = await axiosInstance.get('/api/social/v1/sso/profile', {
    withCredentials: true,
  });

  return data;
}

async function validateUserRegion(accessToken: string) {
  await axiosInstance.get('/api/social/v1/profile', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

async function getUserProfile() {
  let data = null;

  try {
    if (cookie.get('NID_SES_EXISTED') !== 'true') {
      userProfileStore.setNotLogin();

      return;
    }

    data = await validateUserSession();
    await validateUserRegion(data.accessToken);

    if (data.isMember) {
      userProfileStore.setUserProfile(data);
      setToken(data.accessToken);
    } else {
      modalStore.openModal(TosModal, { accessToken: data.accessToken }, false);
    }
  } catch (e) {
    const status = (e as AxiosError)?.response?.status;

    if (status === ErrorCode.SESSION_EXPIRED) {
      cookie.remove('NID_SES');
      cookie.remove('NID_SES_EXISTED');
    } else if (status === ErrorCode.OUT_OF_SERVICE_REGION) {
      modalStore.openModal(
        AlertModal,
        { text: outOfServiceRegion, buttonText: 'ok' },
        false
      );
      axiosInstance.get('/api/social/v1/sso/logout');
    } else {
      modalStore.openModal(ErrorModal, {}, false);
      axiosInstance.get('/api/social/v1/sso/logout');
    }
    userProfileStore.setNotLogin();
  }
}

export default function useUserValidation() {
  useEffect(() => {
    getUserProfile();

    return () => {
      modalStore.closeAllModal();
    };
  }, []);
}
