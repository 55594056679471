import { useEffect, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { getAxiosInstance } from '@/libs/axios';
import { UseFormWatch } from 'react-hook-form';

export function useAutoCompleteList(watch: UseFormWatch<{ keyword: string }>) {
  const [autoCompleteList, setAutoCompleteList] = useState([]);

  useEffect(() => {
    const subscription = watch(
      debounce(500, async ({ keyword }) => {
        if (keyword === '') {
          setAutoCompleteList([]);

          return;
        }
        try {
          const { data } = await getAxiosInstance().get(
            `/api/search/v3/sac.json?q=${keyword}&display=10`
          );

          const list = data.response.result.sacResult.sacList
            ? data.response.result.sacResult.sacList
            : [];

          setAutoCompleteList(list);
        } catch (error) {
          setAutoCompleteList([]);
        }
      })
    );

    return () => subscription.unsubscribe();
  }, [watch]);

  return autoCompleteList;
}
