import { Box, Flex, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

const terms = [
  {
    title: '使用者條款',
    link: 'https://terms2.line.me/music_terms_of_service?lang=zh-Hant',
  },
  {
    title: '隱私權政策',
    link: 'https://terms.line.me/line_tw_privacy_policy?lang=zh-Hant',
  },
  {
    title: '行銷政策',
    link: 'https://terms2.line.me/music_terms_of_marketing?lang=zh-Hant',
  },
  {
    title: '資料使用條款',
    link: 'https://terms2.line.me/music_data_agreement?lang=zh-Hant',
  },
];

const socialIcons = [
  {
    icon: <Box backgroundImage="url(/Footer/Line.svg)" boxSize="24px" />,
    link: 'https://line.me/R/ti/p/%40287rbxyl',
  },
  {
    icon: <Box backgroundImage="url(/Footer/Insta.svg)" boxSize="24px" />,
    link: 'https://lin.ee/8MzHHeB/musicweb',
  },
  {
    icon: <Box backgroundImage="url(/Footer/Facebook.svg)" boxSize="24px" />,
    link: 'https://lin.ee/GE0anUJ/mstw/web',
  },
];

function Footer() {
  return (
    <Box
      backgroundColor={'#f2f2f2'}
      borderTop={'1px solid rgba(30,30,30,.05)'}
      marginLeft={{ base: '0px', lg: '225px' }}
    >
      <Flex
        flexDirection={{ base: 'column', x2l: 'row' }}
        justifyContent={{ base: 'center', lg: 'space-between' }}
        alignItems={{ base: 'center', lg: 'start' }}
        padding={'18px 10px'}
        paddingBottom={{ base: 0, lg: '18px' }}
        color={'#939393'}
        fontSize={'13px'}
        lineHeight={'16px'}
        borderBottom={{ lg: '1px solid rgba(30,30,30,.05)' }}
      >
        <Flex
          fontWeight={'bold'}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignContent={'center'}
        >
          {terms.map(({ title, link }) => (
            <Link
              as={NextLink}
              href={link}
              p={'10px 10px'}
              py={{ base: '10px', x2l: 0 }}
              key={title}
              target="_blank"
              rel="noopener noreferrer"
            >
              {title}
            </Link>
          ))}
        </Flex>
        <Flex
          flexDirection={{ base: 'column', x3l: 'row-reverse' }}
          textAlign={{
            base: 'center',
            lg: 'start',
            x2l: 'right',
            x3l: 'start',
          }}
        >
          <Box
            as="span"
            p={'0px 10px'}
            mb={{ base: '10px', x2l: '36px', x3l: 0 }}
          >
            © LINE Taiwan Limited.
          </Box>
          <Flex
            flexDirection={{
              base: 'column',
              x2l: 'row',
            }}
          >
            <Box as="span" p={'0px 10px'} mb={{ base: '10px', x2l: 0 }}>
              營業人名稱：台灣連線股份有限公司
            </Box>
            <Box as="span" p={'0px 10px'}>
              統一編號：24556886
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        padding={'12px 9px 14px'}
        paddingTop={{ base: '13px', lg: '12px' }}
        justifyContent={{ base: 'center', lg: 'start' }}
      >
        {socialIcons.map(({ icon, link }, index) => (
          <Link
            as={NextLink}
            href={link}
            p={'12px'}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon}
          </Link>
        ))}
      </Flex>
    </Box>
  );
}

export default Footer;
