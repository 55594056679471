import { useRouter } from 'next/router';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

export function useSearchHistory({
  setShouldShowList,
}: {
  setShouldShowList: Dispatch<SetStateAction<boolean>>;
}) {
  const router = useRouter();

  const [searchHistoryList, setSearchHistoryList] = useState<
    string[] | undefined
  >();

  useEffect(() => {
    const data = window.localStorage.getItem('keywords');

    if (data !== null) {
      const list = JSON.parse(data);

      setSearchHistoryList(list);
    }
  }, []);

  useEffect(() => {
    if (searchHistoryList) {
      window.localStorage.setItem(
        'keywords',
        JSON.stringify(searchHistoryList)
      );
    }
  }, [searchHistoryList]);

  const addHistory = useCallback(
    (keyword: string) => {
      const newList = searchHistoryList ? [...searchHistoryList] : [];

      if (!newList.includes(keyword)) {
        if (newList.length >= 10) {
          newList.pop();
          newList.unshift(keyword);
        } else {
          newList.unshift(keyword);
        }
      } else {
        const index = newList.indexOf(keyword);

        newList.splice(index, 1);
        newList.unshift(keyword);
      }
      setShouldShowList(false);
      setSearchHistoryList(newList);
      router.push({
        pathname: '/search',
        query: { query: keyword },
      });
    },
    [router, searchHistoryList, setShouldShowList]
  );

  const removeHistory = useCallback(
    (index: number) => {
      const newList = searchHistoryList ? [...searchHistoryList] : [];

      newList.splice(index, 1);
      setSearchHistoryList(newList);
    },
    [searchHistoryList]
  );

  const clearAllHistory = useCallback(() => {
    setSearchHistoryList([]);
  }, []);

  return { searchHistoryList, addHistory, removeHistory, clearAllHistory };
}
