import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { cardAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

export const cardTheme = defineMultiStyleConfig({
  variants: {
    search: definePartsStyle({
      container: {
        backgroundColor: {
          base: 'rgba(38,38,38,.98)',
          lg: 'hsla(0,0%,100%,.98)',
        },
        borderRadius: '2px',
        boxShadow: '0 2px 5px 2px rgba(0,0,0,.1)',
        marginTop: '5px',
        py: '10px',
        mx: {
          base: '0px',
          lg: '30px',
        },
        width: {
          base: '100vw',
          lg: '100%',
        },
      },
      header: {
        color: '#777',
        fontSize: '14px',
        lineHeight: '18px',
        py: '12px',
      },
      body: {
        p: 0,
      },
    }),
  },
});
