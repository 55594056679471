import { Card, CardBody, Link, List, ListItem, Box } from '@chakra-ui/react';

interface IAutoCompleteListProps {
  items: string[];
  addHistory: (keyword: string) => void;
}

export function AutoCompleteList({
  items,
  addHistory,
}: IAutoCompleteListProps) {
  return (
    <Card variant="search">
      <CardBody
        as={List}
        variant="autoComplete"
        display={items.length !== 0 ? 'block' : 'none'}
      >
        {items.map((item) => (
          <Link
            key={item}
            onClick={() => {
              addHistory(item);
            }}
            display="flex"
            p="11px 20px 12px 28px"
          >
            <Box
              boxSize="20px"
              bg="url(/Common/GraySearch.svg)"
              display="block"
            />
            <ListItem> {item} </ListItem>
          </Link>
        ))}
      </CardBody>
    </Card>
  );
}
