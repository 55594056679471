import { Box } from '@chakra-ui/react';
import Link from 'next/link';

interface IPageNavigatorProps {
  type: 'Main' | 'Magazine' | 'Chart' | 'Recommend';
  pageName: string;
  link: string;
  lighted: boolean;
}

const opacity = {
  lighted: {
    opacity: '1',
    _hover: {
      opacity: '.8',
    },
  },
  normal: {
    opacity: '.8',
    _hover: {
      opacity: '1',
    },
  },
};

const word = {
  lighted: {
    color: '#0ad269',
    fontWeight: 700,
  },
  normal: {
    color: 'white',
  },
};

function PageNavigator({ type, pageName, link, lighted }: IPageNavigatorProps) {
  return (
    <Box className="PageNavigator" marginTop={{ base: '0px', lg: '8px' }}>
      <Link href={link}>
        <Box
          display="block"
          height={{ lg: '32px' }}
          padding={{ base: '13px 0px', lg: '6px 9px' }}
          {...opacity[lighted ? 'lighted' : 'normal']}
        >
          <Box
            as="span"
            display="inline-block"
            fontSize={{ base: '22px', lg: '16px' }}
            lineHeight={{ base: '26px', lg: '22px' }}
            verticalAlign="top"
            {...word[lighted ? 'lighted' : 'normal']}
            _before={{
              content: `url(/SiderBar/${type}${lighted ? 'On' : ''}.svg)`,
              display: 'inline-block',
              width: '22px',
              height: '22px',
              marginRight: '8px',
              verticalAlign: 'top',
            }}
          >
            {pageName}
          </Box>
        </Box>
      </Link>
    </Box>
  );
}

export default PageNavigator;
