/* eslint-disable sonarjs/no-duplicate-string */
import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  container: { alignItems: 'flex-start' },
  control: {
    marginTop: '1px',
    borderRadius: 10,
    height: '22px',
    width: '22px',
    '&[data-checked]': {
      background: 'green.0',
      backgroundColor: 'green.0',
      borderColor: 'green.0',
      ':hover': {
        background: 'green.3',
        borderColor: 'green.3',
      },
      ':active': {
        background: 'green.3',
        borderColor: 'green.3',
      },
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '25px',
    color: '#222',
  },
});

const reversedLabel = definePartsStyle({
  label: {
    display: 'flex',
    lineHeight: '42px',
    margin: '0px',
  },
  control: {
    mt: '10px',
  },
});

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { reversedLabel },
});
