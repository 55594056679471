import { getAxiosInstance } from '@/libs/axios';
import {
  Modal,
  ModalContent,
  ModalContentProps,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Checkbox,
  Link,
  ModalFooterProps,
  useCheckboxGroup,
} from '@chakra-ui/react';
import { IModalProps, modalStore } from '@/utils/Stores/Modal';
import ErrorModal from '../ErrorModal';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const modalContentProps: ModalContentProps = {
  position: 'fixed',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
  margin: '0',
  maxWidth: '100vw',
  padding: '0px',
  overflowY: 'auto',
};

const linkProps = {
  isExternal: true,
  textDecoration: 'underline',
};

const footerProps: ModalFooterProps = {
  display: 'inline-flex',
  flexDirection: 'column',
  fontSize: '16px',
  lineHeight: '24px',
  textAlign: 'center',
};

const buttonProps = {
  variant: 'filled',
  fontSize: '17px',
  fontWeight: '700',
  marginTop: '33px',
  height: '55px',
  _disabled: {
    background: 'gray.4',
    backgroundColor: 'gray.4',
  },
  w: '100%',
};

const cancelLinkProps = {
  fontSize: '15px',
  lineHeight: '18px',
  color: '#999',
  paddingTop: '16px',
  borderBottom: '1px solid #d8d8d8',
  href: '/api/social/v1/sso/logout',
};

export interface ITosModalProps extends IModalProps {
  accessToken: string;
}

async function submitConsent(value: string[], accessToken: string) {
  if (value.includes('2')) {
    const axiosIns = getAxiosInstance();

    const headers = {
      'ACCESS-TOKEN': accessToken,
      'Content-Type': 'application/json',
    };

    try {
      await axiosIns.get('/line/NewInfo/NewMember', { headers });
      await axiosIns.post(
        '/api/user/v1/consents',
        {
          isAgreeToPromotion: value.includes('1'),
          isAgreeToTerms: value.includes('2'),
          isAgreeToJoinOA: value.includes('3'),
        },
        { headers }
      );
      window.location.href = `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/membership`;
    } catch (e) {
      modalStore.openModal(
        ErrorModal,
        {
          closingCallback: () => {
            window.location.href = `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/api/social/v1/sso/logout`;
          },
        },
        true
      );
    }
  }
}

function TosModal({ onClose, accessToken }: ITosModalProps) {
  const { value, getCheckboxProps } = useCheckboxGroup();

  return (
    <Modal
      isOpen
      onClose={onClose}
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalContent {...modalContentProps}>
        <ModalHeader
          padding="70px 0px 0px"
          textAlign="center"
          sx={{ svg: { height: '33px' } }}
        >
          <Box
            _before={{
              content: '""',
              bg: 'url(/Logo/BlackLineMusic.svg) no-repeat center center',
              h: '50px',
              display: 'block',
            }}
          />
        </ModalHeader>
        <ModalBody
          display="flex"
          flexDirection="column"
          w={{ x2l: '980px' }}
          margin="auto"
        >
          <Checkbox {...getCheckboxProps({ value: '1' })} marginTop="60px">
            我同意我同意個人資料供作行銷、促銷及其他目的之同意
            <Link
              {...linkProps}
              href="https://terms2.line.me/music_terms_of_marketing?lang=zh-Hant"
            >
              條款
            </Link>
            {`(任選)。`}
          </Checkbox>
          <Checkbox {...getCheckboxProps({ value: '2' })} marginTop="21px">
            我同意LINE MUSIC
            <Link
              {...linkProps}
              href="https://terms2.line.me/music_terms_of_service?lang=zh-Hant"
            >
              使用條款
            </Link>
            和
            <Link
              {...linkProps}
              href="https://terms.line.me/line_tw_privacy_policy?lang=zh-Hant"
            >
              隱私權政策
            </Link>
            及
            <Link
              {...linkProps}
              href="https://terms2.line.me/music_data_agreement?lang=zh-Hant"
            >
              資料使用條款
            </Link>
            。
          </Checkbox>
          <Checkbox {...getCheckboxProps({ value: '3' })} marginTop="21px">
            {`我同意將LINE MUISC官方帳號加入好友以定期獲得最新資訊及好康消息(任選)。`}
          </Checkbox>
        </ModalBody>
        <ModalFooter
          {...footerProps}
          w={{ x2l: '980px' }}
          margin={{
            base: 'auto',
            lg: '0px calc(50% - 300px)',
            x2l: 'auto',
          }}
        >
          <Box fontWeight="500">
            若欲使用LINE MUSIC服務需同意LINE
            MUSIC使用條款和隱私權政策及資料使用條款
          </Box>
          <Button
            className="agree-btn"
            {...buttonProps}
            onClick={() => submitConsent(value as string[], accessToken)}
            isDisabled={!value.includes('2')}
          >
            Agree
          </Button>
          <Link {...cancelLinkProps}>Cancel</Link>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TosModal;
