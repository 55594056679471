import { Box, useDisclosure } from '@chakra-ui/react';
import { SearchInput } from './SearchInput';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const searchButtonProps = {
  display: 'inline-block',
  margin: { base: '21px 0px', lg: '23px 24px 25px 23px' },
  pos: 'absolute',
  right: { base: '60px', lg: '0' },
  top: '0px',
} as const;

function SearchBar() {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const router = useRouter();

  useEffect(() => {
    if (router.pathname !== '/search') {
      onClose();
    }
  }, [router.pathname, onClose]);

  return (
    <>
      <Box as="span" {...searchButtonProps}>
        <Box
          boxSize="20px"
          bg="url(/Common/WhiteSearch.svg)"
          onClick={onToggle}
        />
      </Box>
      {isOpen && <SearchInput closeSearchInput={onClose} />}
    </>
  );
}

export default SearchBar;
