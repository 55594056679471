import { PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';
import { Box } from '@chakra-ui/react';
import SideBar from '@/components/Layout/SideBar';
import Footer from '@/components/Layout/Footer';

const DynamicAdvertisement = dynamic(
  () => import('@/components/Layout/Advertisement'),
  {
    ssr: false,
  }
);

function Layout({ children }: PropsWithChildren) {
  return (
    <>
      <SideBar />
      <Box
        position="relative"
        margin={{ base: '67px 0 0', lg: '0 0 0 225px' }}
        minHeight={{
          x3l: 'calc( 100vh - 130px)',
          x2l: 'calc( 100vh - 181px )',
          lg: 'calc( 100vh - 217px )',
          xs: 'calc( 100vh - 255px )',
          base: 'calc( 100vh - 290px )',
        }}
        textAlign="center"
      >
        <DynamicAdvertisement />
        {children}
      </Box>
      <Footer />
    </>
  );
}
export default Layout;
