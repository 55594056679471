import { useEffect, useState, useCallback } from 'react';

export function useDetectClickOutsideSearch() {
  const [shouldShowList, setShouldShowList] = useState(true);

  const stopPropagation = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation(),
    []
  );

  useEffect(() => {
    const closeList = () => {
      setShouldShowList(false);
    };

    document.addEventListener('click', closeList);

    return () => {
      document.removeEventListener('click', closeList);
    };
  }, []);

  return { shouldShowList, setShouldShowList, stopPropagation };
}
