import {
  Card,
  CardBody,
  CloseButton,
  Link,
  List,
  ListItem,
} from '@chakra-ui/react';

interface ISearchHistoryListProps {
  items: string[];
  addHistory: (keyword: string) => void;
  removeHistory: (index: number) => void;
  clearAllHistory: () => void;
}

export function SearchHistoryList({
  items,
  addHistory,
  removeHistory,
  clearAllHistory,
}: ISearchHistoryListProps) {
  return (
    <Card variant="search">
      <CardBody as={List} variant="keyword">
        <ListItem
          style={{
            fontSize: '14px',
            lineHeight: '18px',
            color: '#777',
          }}
        >
          最近搜尋
          <CloseButton variant="searchHistoryClean" onClick={clearAllHistory} />
        </ListItem>
        {items.map((item, index) => (
          <Link
            key={item}
            onClick={() => {
              addHistory(item);
            }}
          >
            <ListItem>
              {item}
              <CloseButton
                name={item}
                variant="searchHistoryItem"
                onClick={(e) => {
                  removeHistory(index);
                  e.stopPropagation();
                }}
              />
            </ListItem>
          </Link>
        ))}
      </CardBody>
    </Card>
  );
}
