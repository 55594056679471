import { extendTheme, ThemeOverride } from '@chakra-ui/react';
import styles from './styles';
import { checkboxTheme } from './components/checkbox';
import { buttonTheme } from './components/button';
import { linkTheme } from './components/link';
import { cardTheme } from './components/card';
import { listTheme } from '@/theme/components/list';
import { closeButtonTheme } from '@/theme/components/closeButton';
import { carouselTheme } from './components/carousel';
import { imageTheme } from './components/img';
import { trackListItemTheme } from './components/trackListItem';

const theme: ThemeOverride = extendTheme({
  breakpoints: {
    xxs: '320px',
    xs: '375px',
    sm: '425px',
    md: '594px',
    lg: '768px',
    xl: '993px',
    x2l: '1025px',
    x3l: '1280px',
    x4l: '1500px',
    forMelodyModalSm: '376px', // this is for melody modal and is rarely used
    forMelodyModalMd: '501px', // this is for melody modal and is rarely used
  },
  components: {
    Checkbox: checkboxTheme,
    Button: buttonTheme,
    Link: linkTheme,
    Card: cardTheme,
    List: listTheme,
    CloseButton: closeButtonTheme,
    Carousel: carouselTheme,
    Image: imageTheme,
    TrackListItem: trackListItemTheme,
  },
  ...styles,
});

export default theme;
