/* eslint-disable sonarjs/no-duplicate-string */

const carouselButton = {
  zIndex: 2,
  position: 'absolute',
  transform: 'translate(0, -50%)',
  borderRadius: '50%',
  backgroundColor: 'white',
  boxShadow: '0 1px 3px 0 rgb(0 0 0 / 20%)',
  _hover: {
    backgroundColor: 'gray.2',
  },
};

export const buttonTheme = {
  variants: {
    filled: {
      fontWeight: '400',
      color: 'white',
      background: 'green.2',
      backgroundColor: 'green.2',
      _hover: {
        background: 'green.3',
        borderColor: 'green.3',
      },
      _active: {
        background: 'green.3',
        borderColor: 'green.3',
      },
    },
    lined: {
      fontWeight: '400',
      color: '#232323',
      border: '1px solid',
      borderColor: 'gray.4',
      _hover: {
        borderColor: 'gray.6',
        background: 'gray.2',
      },
      _active: {
        borderColor: 'gray.6',
        background: 'gray.2',
      },
    },
    normalMelodyButton: {
      fontSize: '12px',
      lineHeight: '40px',
      fontWeight: 'normal',
      border: '1px solid #e8e8e8',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: '#efefef',
      },
    },
    detailedMelodyButton: {
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: '700',
      border: '1px solid #e8e8e8',
      backgroundColor: 'white',
      _hover: {
        backgroundColor: '#efefef',
      },
    },
    largeCarouselButton: {
      ...carouselButton,
      top: { x2l: '120px', lg: '82px', md: '100px', sm: '75px', base: '65px' },
    },
    middleCarouselButton: {
      ...carouselButton,
      top: { x3l: '90px', base: '81px' },
    },
    smallCarouselButton: {
      ...carouselButton,
      top: { x2l: '90px', lg: '75px', md: '100px', sm: '75px', base: '65px' },
    },
    chartCarouselButton: {
      ...carouselButton,
      top: '50%',
    },
  },
};
