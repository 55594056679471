import { IModalProps } from '@/utils/Stores/Modal';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

export interface IErrorModalProps extends IModalProps {
  closingCallback?: () => void;
}

function ErrorModal({ onClose, closingCallback }: IErrorModalProps) {
  const closeFunc = () => {
    closingCallback?.();
    onClose();
  };

  return (
    <Modal
      isOpen
      onClose={closeFunc}
      isCentered
      closeOnOverlayClick={false}
      autoFocus={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent width="340px">
        <ModalBody whiteSpace="pre" textAlign="center" padding="35px 0px">
          <Box
            boxSize="60px"
            bg="url(/Common/Error500.svg) no-repeat"
            margin="auto"
          />
          <Box
            marginTop="25px"
            fontSize="20px"
            fontWeight="bold"
            color="gray.font.dark"
          >
            發生錯誤
          </Box>
          <Box marginTop="8px" fontSize="16px" color="gray.font.light">
            系統發生一些問題，我們已回報。 <br />
            請耐心等待修復，謝謝。
          </Box>
          <Box marginTop="25px">
            <Button
              backgroundColor="#00c55e"
              color="#fff"
              fontWeight="500"
              minWidth="80px"
              onClick={closeFunc}
            >
              確認
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ErrorModal;
