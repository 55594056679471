import { defineStyleConfig } from '@chakra-ui/react';

export const carouselTheme = defineStyleConfig({
  variants: {
    middle: {
      width: { x3l: 196, base: 173 },
      height: { x3l: 259, md: 240, base: 220 },
    },
    chart: {
      width: { lg: '50%', md: 330, xs: 345, base: 270 },
      height: { md: 280, base: 300 },
    },
  },
  defaultProps: {
    variant: 'middle',
  },
});
