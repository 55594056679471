import { Box, Link } from '@chakra-ui/react';
import UserMenuPC from './UserMenuPC';
import IdCard from './IdCard';
import UserMenuMobile from './UserMenuMobile';
import getConfig from 'next/config';
import { userProfileStore } from '@/utils/Stores/Login';
import { useSyncExternalStore } from 'react';

const { publicRuntimeConfig } = getConfig();

const loginBlockProps = {
  border: '1px solid hsla(0,0%,84.7%,.13)',
  borderWidth: '1px 0',
  padding: '11px 0',
  display: { base: 'none', lg: 'block' },
  height: '54px',
};

const loginButtonProps = {
  _hover: { textDecoration: 'none' },
};

function LoginModule() {
  const userProfile = useSyncExternalStore(
    userProfileStore.subscribe,
    userProfileStore.getProfile,
    userProfileStore.getProfile
  );

  return (
    <>
      {userProfile ? (
        <>
          <Box {...loginBlockProps}>
            <UserMenuPC
              displayName={userProfile.displayName}
              pictureUrl={userProfile.pictureUrl}
            />
          </Box>
          <Box display={{ base: 'block', lg: 'none' }}>
            <UserMenuMobile
              displayName={userProfile.displayName}
              pictureUrl={userProfile.pictureUrl}
            />
          </Box>
        </>
      ) : (
        <Box {...loginBlockProps}>
          <Link
            {...loginButtonProps}
            href={`${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/api/social/v1/sso/login`}
          >
            <IdCard text="LINE 登入">
              <Box boxSize="30px" bg="url(/DefaultPic/profile.svg)" />
            </IdCard>
          </Link>
        </Box>
      )}
    </>
  );
}

export default LoginModule;
