import { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import Script from 'next/script';
import theme from '@/theme';
import Layout from '@/components/Layout';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';

const DynamicModalLayer = dynamic(() => import('@/components/ModalLayer'), {
  ssr: false,
});

const { publicRuntimeConfig } = getConfig();

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.NEXT_PUBLIC_GA_ID}`}
          strategy="afterInteractive"
          crossOrigin="anonymous"
        />
        <Script
          id="google-analytics"
          strategy="afterInteractive"
          crossOrigin="anonymous"
        >
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${publicRuntimeConfig.NEXT_PUBLIC_GA_ID}');
          `}
        </Script>
        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
          crossOrigin="anonymous"
        >
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${publicRuntimeConfig.NEXT_PUBLIC_GTM_ID}');
          `}
        </Script>
        <Component {...pageProps} />
      </Layout>
      <DynamicModalLayer />
    </ChakraProvider>
  );
}

export default App;
