import dynamic from 'next/dynamic';
import { Box } from '@chakra-ui/react';
import LoginModule from './LoginModule';
import PageNavigator from './PageNavigator';
import ServiceNavigator from './ServiceNavigator';
import getConfig from 'next/config';
import { useRouter } from 'next/router';

const DynamicLoginButton = dynamic(
  () => import('@/components/Layout/SideBar/Menu/LoginButton'),
  {
    ssr: false,
  }
);

const { publicRuntimeConfig } = getConfig();

const serviceData = [
  {
    serviceName: '服務公告',
    link: `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/announcement`,
    shouldOpenNewTab: false,
  },
  {
    serviceName: '付費方案',
    link: `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/shop`,
  },
  {
    serviceName: '兌換序號',
    link: `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/redeem_page`,
  },
  {
    serviceName: '常見問題',
    link: `https://help2.line.me/LINEMusicTW/web/sp?lang=zh-Hant`,
  },
  {
    serviceName: '聯絡我們',
    link: `https://contact-cc.line.me/zh-hant/`,
  },
];

const naviData = [
  {
    type: 'Main',
    pageName: '首頁',
    link: '/',
  },
  {
    type: 'Magazine',
    pageName: '音樂焦點',
    link: `/magazine`,
  },
  {
    type: 'Chart',
    pageName: '鈴聲排行榜',
    link: `/chart`,
  },
  {
    type: 'Recommend',
    pageName: '推薦鈴聲歌單',
    link: `/recommend/playlist`,
  },
] as const;

function Menu() {
  const { pathname } = useRouter();

  return (
    <Box padding="0 15px 54px">
      <LoginModule />
      <Box marginTop={{ base: '0px', lg: '34px' }}>
        {naviData.map((each, index) => (
          <PageNavigator
            key={index}
            {...each}
            lighted={pathname === each.link}
          />
        ))}
      </Box>
      <Box
        height="auto"
        marginTop={{ base: '11px', lg: '34px' }}
        paddingTop="22px"
        borderTop="1px solid hsla(0,0%,84.7%,.13)"
      >
        {serviceData.map((each, index) => (
          <ServiceNavigator key={index} {...each} />
        ))}
        <DynamicLoginButton />
      </Box>
    </Box>
  );
}

export default Menu;
