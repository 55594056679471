import { Store } from '../Store';

type UserProfile = {
  accessToken: string;
  userId: string;
  displayName: string;
  pictureUrl: string;
} | null;

enum LoginStatus {
  Pending,
  LoggedIn,
  NotLogin,
}

class UserProfileStore extends Store<{
  status: LoginStatus;
  profile: UserProfile;
}> {
  private userProfile: UserProfile = null;
  private loginStatus = LoginStatus.Pending;

  constructor() {
    super();
  }

  public getSnapShot = () => {
    return { status: this.loginStatus, profile: this.userProfile };
  };

  public getProfile = () => {
    return this.userProfile;
  };

  public getStatus = () => {
    return this.loginStatus;
  };

  public setUserProfile = (userProfile: UserProfile) => {
    this.userProfile = userProfile;
    this.loginStatus = LoginStatus.LoggedIn;
    this.emitChange();
  };

  public isUserLoggedIn = () => {
    return this.loginStatus === LoginStatus.LoggedIn;
  };
  public isUserNotLoggedIn = () => {
    return this.loginStatus === LoginStatus.NotLogin;
  };
  public isPending = () => {
    return this.loginStatus === LoginStatus.Pending;
  };

  public setNotLogin() {
    this.loginStatus = LoginStatus.NotLogin;
    this.emitChange();
  }
}

const userProfileStore = new UserProfileStore();

export { userProfileStore, LoginStatus };
