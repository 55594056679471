import { ThemeOverride } from '@chakra-ui/react';

const styles: ThemeOverride = {
  layerStyles: {
    pageLayout: {
      width: { x4l: '1160px', x3l: '964px' },
      margin: { x3l: '0 auto', lg: '0 43px' },
    },
  },
  colors: {
    primary: '#0ad269',
    green: {
      0: '#0ee071',
      1: '#00c55e',
      2: '#06c755',
      3: '#13a754',
    },
    white: '#fff',
    red: {
      warning: '#f22',
    },
    black: '#232323',
    gray: {
      0: '#fbfbfb',
      1: '#f2f2f2',
      2: '#efefef',
      3: '#dee5ec',
      4: '#d7d7d7',
      5: '#c0c0c0',
      6: '#bababa',
      7: '#999',
      8: '#666',
      background: {
        light: '#fbfbfb',
        dark: '#f2f2f2',
      },
      filledBtn: {
        disabled: '#dee5ec',
      },
      font: {
        light: '#999',
        dark: '#666',
      },
    },
  },
  fonts: {
    body: '-apple-system, Arial, "Noto Sans CJK TC", "Microsoft JhengHei", sans-serif',
  },
  styles: {
    global: {
      body: {
        backgroundColor: '#fbfbfb',
        WebkitFontSmoothing: 'auto',
        MozOsxFontSmoothing: 'auto',
      },
      '.ellipsis_text': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordBreak: 'break-all',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
      },
      '.ellipsis_text.one_line': {
        WebkitLineClamp: '1',
      },
      '.ellipsis_text.two_line': {
        WebkitLineClamp: '2',
      },
    },
  },
};

export default styles;
