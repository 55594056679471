import Link from 'next/link';
import { Box, BoxProps, useMediaQuery } from '@chakra-ui/react';
import Menu from './Menu';
import MenuDrawer from '@/components/Layout/SideBar/MenuDrawer';
import SearchBar from './SearchBar';
import useUserValidation from '@/hooks/useUserValidation';

const sideBarProps: BoxProps = {
  bgColor: '#000',
  top: '0',
  bottom: { lg: '0' },
  left: '0',
  width: { base: 'auto', lg: '225px' },
  right: { base: '0' },
  position: 'fixed',
  zIndex: 10,
};

const lineMuiscLogoProps = {
  h: '25px',
  w: '111px',
  margin: { base: '21px 20px', lg: '23px 22px 25px 22px' },
};

function SideBar() {
  useUserValidation();
  const [isSmallerThan768] = useMediaQuery('(max-width: 767px)');

  return (
    <Box {...sideBarProps}>
      <Box>
        <Link href="/" style={{ display: 'inline-flex' }}>
          <Box
            {...lineMuiscLogoProps}
            bg="url('/Logo/WhiteLineMusic.svg') no-repeat center center"
          />
        </Link>
        <SearchBar />
        {isSmallerThan768 && <MenuDrawer />}
      </Box>
      <Box display={{ base: 'none', lg: 'block' }}>
        {!isSmallerThan768 && <Menu />}
      </Box>
    </Box>
  );
}

export default SideBar;
