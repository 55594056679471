import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/input';
import { Box, CloseButton } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
  useDetectClickOutsideSearch,
  useAutoCompleteList,
  useSearchHistory,
} from '@/hooks/searchHooks';
import { SearchHistoryList } from './SearchHistoryList';
import { AutoCompleteList } from './AutoCompleteList';

const searchBarStyles = {
  position: 'fixed',
  top: '0px',
  left: {
    base: '0px',
    lg: '225px',
  },
  right: '0px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '13px',
  background: {
    base: '#000',
    lg: 'white',
  },
  zIndex: 10,
  height: '67px',
} as const;

const inputProps = {
  color: {
    base: 'white',
    lg: 'black',
  },
  fontSize: '21px',
  lineHeight: '26px',
  border: '0',
};

interface ISearchInputProps {
  closeSearchInput: () => void;
}

export function SearchInput({ closeSearchInput }: ISearchInputProps) {
  const { shouldShowList, setShouldShowList, stopPropagation } =
    useDetectClickOutsideSearch();

  const { register, watch, handleSubmit, setValue } = useForm<{
    keyword: string;
  }>({
    defaultValues: { keyword: '' },
  });

  const eventHandler = register('keyword', {});

  const autoCompleteList = useAutoCompleteList(watch);

  const { searchHistoryList, addHistory, removeHistory, clearAllHistory } =
    useSearchHistory({
      setShouldShowList,
    });

  const goToSearchPage = (keyword: string) => {
    setValue('keyword', keyword);
    addHistory(keyword);
  };

  return (
    <Box {...searchBarStyles} onClick={stopPropagation}>
      <form
        onSubmit={handleSubmit(({ keyword }, e) => {
          addHistory(keyword);
          e?.target[0].blur();
        })}
        style={{ width: '100%' }}
      >
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Box
              boxSize="20px"
              bg="url(/Common/GraySearch.svg)"
              display="block"
            />
          </InputLeftElement>
          <Input
            placeholder="搜尋"
            autoComplete="off"
            {...eventHandler}
            {...inputProps}
            onFocus={() => setShouldShowList(true)}
          />
          <InputRightElement>
            <CloseButton variant="searchHistory" onClick={closeSearchInput} />
          </InputRightElement>
        </InputGroup>
      </form>
      {shouldShowList &&
        (autoCompleteList.length > 0 ? (
          <AutoCompleteList
            items={autoCompleteList}
            addHistory={goToSearchPage}
          />
        ) : (
          searchHistoryList &&
          searchHistoryList.length > 0 && (
            <SearchHistoryList
              items={searchHistoryList}
              addHistory={goToSearchPage}
              removeHistory={removeHistory}
              clearAllHistory={clearAllHistory}
            />
          )
        ))}
    </Box>
  );
}
