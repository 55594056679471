import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { listAnatomy as parts } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const itemProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  _hover: { bg: 'rgba(0,0,0,.03)' },
};

export const listTheme = defineMultiStyleConfig({
  variants: {
    keyword: definePartsStyle({
      container: {
        p: 0,
      },
      item: {
        p: '12px 30px',
        fontSize: '16px',
        lineHeight: '19px',
        color: {
          base: 'white',
          lg: '#232323',
        },
        ...itemProps,
      },
    }),
    autoComplete: definePartsStyle({
      container: {
        p: 0,
      },
      item: {
        paddingLeft: '8px',
        fontSize: '16px',
        lineHeight: '19px',
        color: {
          base: 'white',
          lg: '#777',
        },
        ...itemProps,
      },
    }),
  },
});
