import { defineStyleConfig, defineStyle } from '@chakra-ui/react';

export const imageTheme = defineStyleConfig({
  sizes: {
    thumbnailSmall: defineStyle({ boxSize: { base: '50px', md: '40px' } }),
    thumbnail: defineStyle({ boxSize: '54px' }),
    default: defineStyle({ boxSize: { base: '162px', x3l: '180px' } }),
    endPagePreview: defineStyle({
      boxSize: {
        x3l: '190px',
        xl: '210px',
        lg: '176px',
        base: '200px',
      },
    }),
    resizeWithScreen: defineStyle({ boxSize: '100%', aspectRatio: '1' }),
    deformable: defineStyle({
      boxSize: { md: '100%', base: '80px' },
      minW: { md: '100%', base: '80px' },
      aspectRatio: '1',
    }),
    description: defineStyle({
      boxSize: '84px',
    }),
    magazineAlbumCover: defineStyle({
      boxSize: { md: '180px', base: '100%' },
      aspectRatio: '1',
    }),
    magazineImage: defineStyle({
      maxH: { md: '500px', base: 'none' },
    }),
  },
  variants: {
    circle: defineStyle({ borderRadius: 'full' }),
  },
});
