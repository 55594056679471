import { ReactElement } from 'react';
import { Store } from '../Store';

export interface IModalProps {
  onClose: () => void;
}

export interface ModalData {
  Component: (props: IModalProps) => ReactElement;
  props: Omit<IModalProps, 'onClose'>;
  onClose: () => void;
}

class ModalStore extends Store<ModalData[]> {
  private modals: ModalData[] = [];

  constructor() {
    super();
  }

  public closeAllModal = () => {
    this.modals = [];

    this.emitChange();
  };

  public closeModal = () => {
    this.modals = this.modals.slice(0, this.modals.length - 1);
    this.emitChange();
  };

  public openModal<I extends IModalProps>(
    modal: (props: I) => ReactElement,
    props: Omit<I, 'onClose'>,
    shouldCloseAll: boolean
  ) {
    const onClose = shouldCloseAll ? this.closeAllModal : this.closeModal;

    this.modals = [
      ...this.modals,
      { Component: modal as ModalData['Component'], props, onClose },
    ];
    this.emitChange();
  }

  public getSnapShot = () => {
    return this.modals;
  };
}

const modalStore = new ModalStore();

export { modalStore };
