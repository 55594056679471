import { useEffect, useState, useSyncExternalStore } from 'react';
import { Box } from '@chakra-ui/react';
import Menu from '../Menu';
import { userProfileStore } from '@/utils/Stores/Login';

interface DrawerFunc {
  open?: () => void;
  close?: () => void;
}

function MenuDrawer() {
  const isPending = useSyncExternalStore(
    userProfileStore.subscribe,
    userProfileStore.isPending,
    userProfileStore.isPending
  );

  const [drawerFunc, setDrawerFunc] = useState<DrawerFunc>({
    open: undefined,
    close: undefined,
  });

  useEffect(() => {
    if (isPending) {
      return;
    }
    const menuDrawer = document.querySelector(
      '.MenuDrawer'
    ) as HTMLElement | null;

    const elements = document.querySelectorAll('.PageNavigator');

    const open = () => {
      if (menuDrawer) {
        menuDrawer.style.display = 'block';
        document.body.style.overflow = 'hidden';
      }
    };

    const close = () => {
      if (menuDrawer) {
        menuDrawer.style.display = 'none';
        document.body.style.overflow = '';
      }
    };

    setDrawerFunc({ open, close });

    for (let i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', close);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      for (let i = 0; i < elements.length; i++) {
        elements[i].removeEventListener('click', close);
      }
      document.body.style.overflow = '';
    };
  }, [isPending]);

  return (
    <>
      <Box
        as="span"
        display={{ base: 'inline-block', lg: 'none' }}
        right="0"
        margin="21px 20px"
        onClick={(e) => {
          drawerFunc.open?.();
          e.stopPropagation();
        }}
        data-testid="menu-button"
        cursor="pointer"
        float="right"
      >
        <Box
          clipPath={`polygon(0 0,100% 0,100% 10%,0% 10%,0 0,
            0 45%,100% 45%,100% 55%,0 55%,0 45%,
            0 90%,100% 90%,100% 100%,0 100%,0 90%)`}
          boxSize="20px"
          bg="white"
        />
      </Box>
      <Box
        className="MenuDrawer"
        display="none"
        pos="fixed"
        bgColor="#000"
        top="0px"
        right="0px"
        left="0px"
        h="fit-content"
      >
        <Box textAlign="right">
          <Box
            data-testid="drawer-close-button"
            cursor="pointer"
            m="19px 7px 10px"
            w="36px"
            h="36px"
            display="inline-block"
            onClick={drawerFunc.close}
            _after={{
              content: '""',
              display: 'inline-block',
              width: '36px',
              height: '36px',
              bgImage: `url(/Common/CloseCross.svg)`,
              bgSize: '100%',
            }}
          />
        </Box>
        <Box
          overflowY="auto"
          h={{ lg: 'fit-content', base: 'calc(100vh - 60px)' }}
        >
          <Box>
            <Menu />
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default MenuDrawer;
