import {
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
} from '@chakra-ui/react';
import Link from 'next/link';
import IdCard from '../IdCard';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

interface IUserMenuPCProps {
  displayName: string;
  pictureUrl: string;
}

const menuItemProps = {
  padding: '6px 20px 7px',
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'left',
  color: 'black',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  _hover: {
    background: 'gray.background.dark',
  },
} as const;

const menuItems = [
  {
    text: '會員身份',
    url: `/membership`,
  },
  {
    text: '登出',
    url: `${publicRuntimeConfig.LINE_MUSIC_ENDPOINT_URL}/api/social/v1/sso/logout`,
  },
];

function UserMenuPC({ displayName, pictureUrl }: IUserMenuPCProps) {
  return (
    <Menu offset={[86, 11]} closeOnSelect={true} autoSelect={false}>
      <MenuButton width="100%" textAlign="inherit">
        <IdCard text={displayName} textColor="#ccc" lineHeight="30px">
          {pictureUrl ? (
            <Image
              borderRadius="full"
              src={pictureUrl}
              alt={displayName}
              boxSize="30px"
            />
          ) : (
            <Box boxSize="30px" bg="url(/DefaultPic/profile.svg)" />
          )}
        </IdCard>
      </MenuButton>
      <MenuList minWidth="124px" padding="11px 0px">
        {menuItems.map((each, index) => (
          <MenuItem key={index} {...menuItemProps}>
            <Link href={each.url}>{each.text}</Link>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default UserMenuPC;
